<template>
  <div id="initialDetection">
    <el-dialog
      :title="initialDetectionFormTitle"
      width="1200px"
      :visible.sync="initialDetectionDialogVisible"
      :close-on-click-modal="false"
      @close="initialDetectionDialogClose"
    >
      <el-form
        ref="initialDetectionFormRef"
        :model="initialDetectionForm"
        :rules="initialDetectionFormRules"
        label-position="right"
        label-width="130px"
      >
        <el-divider content-position="left">
          无菌检测室运行情况
        </el-divider>
        <el-row>
          <el-col :span="8">
            <el-form-item label="空调运行时间" prop="runtime">
              <el-date-picker v-model="initialDetectionForm.runtime" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="紫外消毒时间" prop="uvDisinfectionTime">
              <el-date-picker v-model="initialDetectionForm.uvDisinfectionTime" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="温度" prop="temperature">
              <el-input v-model="initialDetectionForm.temperature" placeholder="请输入温度" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="湿度" prop="humidity">
              <el-input v-model="initialDetectionForm.humidity" placeholder="请输入湿度" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="压差" prop="pressure">
              <el-input v-model="initialDetectionForm.pressure" placeholder="请输入压差" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="区域" prop="area">
              <el-select v-model="initialDetectionForm.area" placeholder="请选择区域">
                <el-option key="1" label="洁净区" :value="1" />
                <el-option key="2" label="非洁净区" :value="2" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="数量级" prop="magnitude">
              <el-select v-model="initialDetectionForm.magnitude" placeholder="请选择数量级">
                <el-option key="1" label="1万级" :value="1" />
                <el-option key="2" label="10万级" :value="2" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="initialDetectionForm.productName" placeholder="请输入产品名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="批号" prop="batchNo">
              <el-input v-model="initialDetectionForm.batchNo" placeholder="请输入批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="规格" prop="spec">
              <el-input v-model="initialDetectionForm.spec" placeholder="请输入规格" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="检验数量" prop="quantity">
              <el-input v-model="initialDetectionForm.quantity" placeholder="请输入检验数量" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <vxe-toolbar v-if="initialDetectionFormTitle !== '待灭菌产品初始污染菌检测记录详情'">
          <template #buttons>
            <vxe-button @click="insertRowEvent()">
              新增
            </vxe-button>
          </template>
        </vxe-toolbar>
        <vxe-table
          ref="xTable"
          border
          class="mytable-scrollbar"
          resizable
          show-overflow
          keep-source
          :data="detectionDetailList"
          :edit-config="{trigger: 'click', mode: 'cell'}"
        >
          <vxe-table-column
            field="project"
            title="实验项目"
            width="100"
            :edit-render="{name: 'textarea', props: {clearable: true}}"
          />
          <vxe-table-column
            field="bacteriaName"
            title="菌液名称"
            width="100"
            :edit-render="{name: 'textarea', props: {clearable: true}}"
          />
          <vxe-table-colgroup title="试验组/培养天数">
            <vxe-table-column
              field="testGroupDay1"
              title="1"
              width="70"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="testGroupDay2"
              title="2"
              width="70"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="testGroupDay3"
              title="3"
              width="70"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="testGroupDay4"
              title="4"
              width="70"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="testGroupDay5"
              title="5"
              width="70"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
          </vxe-table-colgroup>
          <vxe-table-colgroup title="菌液组/培养天数">
            <vxe-table-column
              field="bacteriaDay1"
              title="1"
              width="70"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="bacteriaDay2"
              title="2"
              width="70"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="bacteriaDay3"
              title="3"
              width="70"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="bacteriaDay4"
              title="4"
              width="70"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="bacteriaDay5"
              title="5"
              width="70"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
          </vxe-table-colgroup>
          <vxe-table-colgroup title="稀释剂组/培养天数">
            <vxe-table-column
              field="thinnerGroupDay1"
              title="1"
              width="70"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="thinnerGroupDay2"
              title="2"
              width="70"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="thinnerGroupDay3"
              title="3"
              width="70"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="thinnerGroupDay4"
              title="4"
              width="70"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="thinnerGroupDay5"
              title="5"
              width="70"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
          </vxe-table-colgroup>
          <vxe-table-colgroup title="供试品组">
            <vxe-table-column
              field="plate1"
              title="平皿1"
              width="70"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="plate2"
              title="平皿2"
              width="70"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="average"
              title="平均"
              width="70"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
          </vxe-table-colgroup>
          <vxe-table-column
            field="bacterialRecovery"
            title="稀释剂组菌数回收"
            width="70"
            :edit-render="{name: '$input', props: {clearable: true}}"
          />
          <vxe-table-column
            field="incubatorNo"
            title="培养箱编号"
            width="70"
            :edit-render="{name: '$input', props: {clearable: true}}"
          />
          <vxe-table-column
            field="cultureTemperature"
            title="培养温度"
            width="70"
            :edit-render="{name: '$input', props: {clearable: true}}"
          />

          <vxe-table-column v-if="initialDetectionFormTitle !== '待灭菌产品初始污染菌检测记录详情'" title="操作" width="80">
            <template #default="{ row }">
              <template>
                <vxe-button size="mini" @click="removeRowEvent(row)">
                  删除
                </vxe-button>
              </template>
            </template>
          </vxe-table-column>
        </vxe-table>
        <el-row>
          <el-col :span="24">
            <el-form-item label="验证结论" prop="conclusion">
              <el-input
                v-model="initialDetectionForm.conclusion"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="检验人员" prop="inspector">
              <el-input v-model="initialDetectionForm.inspector" placeholder="请输入检验人员" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检验时间" prop="testDate">
              <el-date-picker v-model="initialDetectionForm.testDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="复核人员" prop="reviewer">
              <el-input v-model="initialDetectionForm.reviewer" placeholder="请输入复核人员" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="复核时间" prop="reviewDate">
              <el-date-picker v-model="initialDetectionForm.reviewDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="initialDetectionDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="initialDetectionFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="部门">
        <el-input v-model="searchForm.dept" placeholder="请输入部门" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="initialDetectionPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="runtime" label="空调运行时间" />
      <el-table-column prop="uvDisinfectionTime" label="紫外消毒时间" />
      <el-table-column prop="temperature" label="温度" />
      <el-table-column prop="humidity" label="湿度" />
      <el-table-column prop="pressure" label="压差" />
      <el-table-column prop="area" label="区域">
        <template slot-scope="scope">
          <span v-if="scope.row.area == 1">洁净区</span>
          <span v-if="scope.row.area == 2">非洁净区</span>
        </template>
      </el-table-column>
      <el-table-column prop="magnitude" label="数量级">
        <template slot-scope="scope">
          <span v-if="scope.row.magnitude == 1">1万级</span>
          <span v-if="scope.row.magnitude == 2">10万级</span>
        </template>
      </el-table-column>
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="spec" label="规格" />
      <el-table-column prop="quantity" label="检验数量" />
      <el-table-column prop="conclusion" label="验证结论" />
      <el-table-column prop="conclusion" label="结论" />
      <el-table-column prop="inspector" label="检验人员" />
      <el-table-column prop="testDate" label="检验日期" />
      <el-table-column prop="reviewer" label="复核人员" />
      <el-table-column prop="reviewDate" label="复核日期" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="initialDetectionPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addInitialDetection, deleteInitialDetection, updateInitialDetection, selectInitialDetectionInfo, selectInitialDetectionList } from '@/api/quality/initialBacteriaDetection'

export default {
  data () {
    return {
      initialDetectionDialogVisible: false,
      initialDetectionFormTitle: '',
      initialDetectionForm: {
        id: '',
        runtime: '',
        uvDisinfectionTime: '',
        temperature: '',
        humidity: '',
        pressure: '',
        area: '',
        magnitude: '',
        productName: '',
        batchNo: '',
        spec: '',
        quantity: '',
        conclusion: '',
        inspector: '',
        testDate: '',
        reviewer: '',
        reviewDate: '',
        detectionDetailJson: ''
      },
      initialDetectionFormRules: {
        productName: [{ required: true, message: '产品名称不能为空', trigger: ['blur', 'change']}]
      },
      initialDetectionPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        dept: ''
      },
      detectionDetailList: []
    }
  },
  created () {
    selectInitialDetectionList(this.searchForm).then(res => {
      this.initialDetectionPage = res
    })
  },
  methods: {
    initialDetectionDialogClose () {
      this.$refs.initialDetectionFormRef.resetFields()
      this.detectionDetailList = []
      this.initialDetectionForm.id = ''
    },
    initialDetectionFormSubmit () {
      if (this.initialDetectionFormTitle === '待灭菌产品初始污染菌检测记录详情') {
        this.initialDetectionDialogVisible = false
        return
      }
      this.$refs.initialDetectionFormRef.validate(async valid => {
        if (valid) {
          this.initialDetectionForm.detectionDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.initialDetectionFormTitle === '新增待灭菌产品初始污染菌检测记录') {
            await addInitialDetection(this.initialDetectionForm)
          } else if (this.initialDetectionFormTitle === '修改待灭菌产品初始污染菌检测记录') {
            await updateInitialDetection(this.initialDetectionForm)
          }
          this.initialDetectionPage = await selectInitialDetectionList(this.searchForm)
          this.initialDetectionDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.initialDetectionFormTitle = '新增待灭菌产品初始污染菌检测记录'
      this.initialDetectionDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteInitialDetection(row.id)
        if (this.initialDetectionPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.initialDetectionPage = await selectInitialDetectionList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.initialDetectionFormTitle = '修改待灭菌产品初始污染菌检测记录'
      this.initialDetectionDialogVisible = true
      this.selectInitialDetectionInfoById(row.id)
    },
    handleInfo (index, row) {
      this.initialDetectionFormTitle = '待灭菌产品初始污染菌检测记录详情'
      this.initialDetectionDialogVisible = true
      this.selectInitialDetectionInfoById(row.id)
    },
    selectInitialDetectionInfoById (id) {
      selectInitialDetectionInfo(id).then(res => {
        this.initialDetectionForm.id = res.id
        this.initialDetectionForm.runtime = res.runtime
        this.initialDetectionForm.uvDisinfectionTime = res.uvDisinfectionTime
        this.initialDetectionForm.temperature = res.temperature
        this.initialDetectionForm.humidity = res.humidity
        this.initialDetectionForm.pressure = res.pressure
        this.initialDetectionForm.area = res.area
        this.initialDetectionForm.magnitude = res.magnitude
        this.initialDetectionForm.productName = res.productName
        this.initialDetectionForm.batchNo = res.batchNo
        this.initialDetectionForm.spec = res.spec
        this.initialDetectionForm.quantity = res.quantity
        this.initialDetectionForm.conclusion = res.conclusion
        this.initialDetectionForm.inspector = res.inspector
        this.initialDetectionForm.testDate = res.testDate
        this.initialDetectionForm.reviewer = res.reviewer
        this.initialDetectionForm.reviewDate = res.reviewDate
        this.detectionDetailList = res.detectionDetailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectInitialDetectionList(this.searchForm).then(res => {
        this.initialDetectionPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectInitialDetectionList(this.searchForm).then(res => {
        this.initialDetectionPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectInitialDetectionList(this.searchForm).then(res => {
        this.initialDetectionPage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    }
  }
}
</script>

<style>
</style>
