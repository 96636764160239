import axios from '@/common/axios'
import qs from 'qs'

export function addInitialDetection (data) {
  return axios({
    method: 'post',
    url: '/quality/initialDetection/add',
    data: qs.stringify(data)
  })
}

export function deleteInitialDetection (id) {
  return axios({
    method: 'delete',
    url: '/quality/initialDetection/delete/' + id
  })
}

export function updateInitialDetection (data) {
  return axios({
    method: 'put',
    url: '/quality/initialDetection/update',
    data: qs.stringify(data)
  })
}

export function selectInitialDetectionInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/initialDetection/info/' + id
  })
}

export function selectInitialDetectionList (query) {
  return axios({
    method: 'get',
    url: '/quality/initialDetection/list',
    params: query
  })
}
